import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "korrigieren",
}

const Page = () => {
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const data = useStaticQuery(graphql`
    query AnreiseQuery {
      tagebuch: file(relativePath: { eq: "kurse/tagebuch-seite-2.png" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zum Tagebuch"
          to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/korrigieren/aufgabe-01"
        />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Hölderlin korrigieren" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin korrigieren
        </Heading>
        <Grid columns={[1, 2]}>
          <Stack>
            <Paragraph>
              Hölderlin begann seine Reise am 2. Juni 1788. Am Abend hielt er
              vor dem Schlafengehen die Ereignisse des Tages fest.
            </Paragraph>
            <Paragraph>
              Lies den Eintrag und erfahre, was er an diesem Tag alles erlebt
              hat. Dir wird auffallen, dass Hölderlin einige Rechtschreib-Fehler
              gemacht hat. Zu seiner Zeit gab es nämlich noch keine festen
              Regeln dafür.
            </Paragraph>
          </Stack>
          <Image
            image={data.tagebuch.childImageSharp.gatsbyImageData}
            alt="Tagebuch"
            caption="Eine Seite aus Hölderlins Tagebuch"
          />
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
